import React, { useState, useEffect } from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import FolderIcon from "@mui/icons-material/Folder";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import { Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Link } from "react-router-dom";

export default function Footer({ theme }) {
  const matches = useMediaQuery("(min-width:480px)");
  const [value, setValue] = React.useState("recents");
  const year = new Date().getFullYear();
  const [barheight, setBarHeight] = useState("250px");
  const [iconmargin, setIconMargin] = useState("200px");
  const [rendering, setRendering] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //   const BottomNavigationAction = styled(MuiBottomNavigationAction)(`
  //   color: white;
  //   fontFamily: ${theme.customfont.main};
  //   &.Mui-selected {
  //     color: ${theme.palette.oranje.main};
  //     fontFamily: ${theme.customfont.main};
  //   }
  // `);

  useEffect(() => {
    setRendering(true);
    if (!matches) {
      setBarHeight("100px");
      setIconMargin("60px");
    } else {
      setBarHeight("250px");
      setIconMargin("220px");
    }
    setRendering(false);
    //console.log(matches);
    //console.log(barheight);
    console.log("Rendering");
  }, [matches]);

  return (
    <>
      {rendering ? (
        <div>Loading</div>
      ) : (
        <BottomNavigation
          value={value}
          onChange={handleChange}
          sx={{ height: barheight, bottom: 0 }}
        >
          <Typography sx={{ padding: 1 }} variant="body1" color="white">
            &copy; {year} - Windroos
          </Typography>
          <BottomNavigationAction
            sx={{ marginBottom: iconmargin }}
            component={Link}
            to="/kalender"
            label="Kalender"
            value="kalender"
            icon={<CalendarMonthTwoToneIcon />}
          />

          <BottomNavigationAction
            sx={{ marginBottom: iconmargin }}
            component={Link}
            to="/contact"
            label="Contact"
            value="contact"
            icon={<LocationOnIcon />}
          />
          <BottomNavigationAction
            sx={{ marginBottom: iconmargin }}
            component={Link}
            label="Documenten"
            to="/documenten"
            value="folder"
            icon={<FolderIcon />}
          />
          {!matches ? null : (
            <Typography sx={{ padding: 1 }} variant="body1" color="white">
              <strong>Een school van Het Sticht</strong>
              <ul>
                <li>
                  <a
                    href="https://www.aloysius-school.nl/"
                    style={{ textDecoration: "None", color: "#fff" }}
                  >
                    Aloysius school
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.daltonnicolaas.net/"
                    style={{ textDecoration: "None", color: "#fff" }}
                  >
                    Daltonschool Nicolaas
                  </a>
                </li>
                <li>
                  <a
                    href="http://www.damiaanschool.nl/"
                    style={{ textDecoration: "None", color: "#fff" }}
                  >
                    Damiaanschool
                  </a>
                </li>
                <li>
                  <a
                    href="http://www.bsdegriffel.nl/"
                    style={{ textDecoration: "None", color: "#fff" }}
                  >
                    KBS De Griffel
                  </a>
                </li>
                <li>
                  <a
                    href="http://www.montinischool.nl/"
                    style={{ textDecoration: "None", color: "#fff" }}
                  >
                    Montini Jenaplanschool
                  </a>
                </li>
                <li>
                  <a
                    href="http://www.valkenheuvel.nl/"
                    style={{ textDecoration: "None", color: "#fff" }}
                  >
                    Valkenheuvel
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.kameleondendolder.nl/"
                    style={{ textDecoration: "None", color: "#fff" }}
                  >
                    De Kameleon
                  </a>
                </li>
              </ul>
            </Typography>
          )}
        </BottomNavigation>
      )}
    </>
  );
}
