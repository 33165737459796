import React from "react";
import Typography from "@mui/material/Typography";

function NieuwsSingle() {
	return (
		<div>
			<Typography variant="h4" color="initial" sx={{ margin: "10px" }}>
				26 april 2024 - Lentefeest
			</Typography>
			<Typography
				variant="body1"
				color="initial"
				sx={{ maxWidth: "650px", margin: "10px" }}
			>
				Als onderdeel van de vieringen op de Windroos, is het lentefeest een
				feest dat ieder jaar terugkomt. We vieren dit feest dit jaar op 26 april
				2024 met de hele school. Het thema dit jaar is "Buiten spelen"
			</Typography>
			<img
				alt="opendag"
				style={{ width: "50%", borderRadius: "5%" }}
				src="https://wrassets.boss-tech.nl/beelden/lentefeest.jpg"
			/>
		</div>
	);
}

export default NieuwsSingle;
