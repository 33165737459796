import React from "react";
import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function Team() {
	function createData(name, primaryRes, secondaryRes) {
		return { name, primaryRes, secondaryRes };
	}

	const rows = [
		createData(
			"Freek van der Brugge",
			"Directeur a.i.",
			"Anti-pestcoördinator"
		),
		createData(
			"Marijke Hovenier",
			"Intern begeleider",
			"Groepsleerkracht groep 7/8, school-opleider, vertouwenspersoon"
		),
		createData("Nienke Jubbega", "Groepsleerkracht groep 1/2a", ""),
		createData("Sharon Reimert", "Groepsleerkracht groep 1/2b", ""),
		createData(
			"Tilly Baas",
			"Groepsleerkracht groep 3",
			"Coördinator onderbouw"
		),
		createData("Lianne Dorrestijn", "Groepsleerkracht groep 4"),
		createData(
			"Hanneke Nijenhuis",
			"Groepsleerkracht groep 3",
			"Groepsleerkracht groep 4 (om de week 1 dag)"
		),
		createData(
			"Loes Vermaeten",
			"Groepsleerkracht groep 5",
			"Coördinator bovenbouw, kunst- en cultuurcoördinator"
		),
		createData(
			"Marije Koekkoek",
			"Groepsleerkracht groep 7/8",
			"Groepsleerkracht groep 5/6 (1 dg per week)"
		),

		createData(
			"Julia van Renes",
			"Groepsleerkracht groep 6/7",
			"Lid MR, Lid GMR"
		),
		createData(
			"Noortje van Lieshout",
			"Groepsleerkracht groep 7/8",
			"Freinetcoördinator, schoolopleider"
		),
		createData(
			"Marian van Heerde",
			"Onderwijsondersteuner/RT",
			"contactpersoon ouderverening <-> team"
		),
		createData("Levina Verhoef", "Onderwijsondersteuner/RT"),
		createData("Jorrit ", "Assistent conciërge", ""),
	];

	return (
		<div style={{ maxWidth: "840px", marginLeft: "10%" }}>
			<Typography variant="h5">Team Windroos</Typography>
			<Typography variant="body1" sx={{ marginBottom: 2 }}>
				Ons team bestaat uit een enthousiaste groep leerkrachten en
				onderwijsondersteuners (RT), allemaal met een aantekening voor Freinet
				onderwijs. Daarnaast hebben we een vakleerkracht voor gym.
			</Typography>
			<img
				src="https://wrassets.boss-tech.nl/Team1.jpg"
				alt="team windroos"
				style={{ width: "100%", borderRadius: "5%" }}
				loading="lazy"
			/>
			<Typography variant="body1" sx={{ marginBottom: 6 }}>
				achter vlnr: Levina, Noortje, Hanneke, Rinske, Sharon, Gijs, Tilly
				<br />
				voor vlnr: Julia, Nienke, Lianne, Loes, Marijke, Marian
			</Typography>

			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell sx={{ fontFamily: "windroos, sans serif" }}>
								<strong>Teamlid</strong>
							</TableCell>
							<TableCell
								sx={{ fontFamily: "windroos, sans serif" }}
								align="left"
							>
								<strong>Verantwoordelijk voor</strong>
							</TableCell>
							<TableCell
								sx={{ fontFamily: "windroos, sans serif" }}
								align="left"
							>
								<strong>Andere rollen</strong>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row) => (
							<TableRow
								key={row.name}
								sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
							>
								<TableCell component="th" scope="row">
									{row.name}
								</TableCell>
								<TableCell align="left">{row.primaryRes}</TableCell>
								<TableCell align="left">{row.secondaryRes}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

export default Team;
