import React from "react";
import Typography from "@mui/material/Typography";

function Medezeggenschap() {
  return (
    <div style={{ maxWidth: "720px", marginLeft: "10%" }}>
      <Typography variant="h5">Medezeggenschap</Typography>
      <Typography variant="body1" sx={{ marginBottom: 16 }}>
        De Medenzeggenschapsraad is het orgaan waarin vertegenwoordigers van het
        team en ouders zitten om mee te kunnen spreken met de schoolleiding.
        <ul>
          <li>Rick Steggerda (ouder)</li>
          <li>Nina van Haarlem (ouder)</li>
          <li>Julia van Renes (team)</li>
          <li>Tilly Baas (team)</li>
        </ul>
        <br />
        De MR leden worden benoemd voor een periode van 3 jaar. Julia van Renes
        en Rick Steggerda zijn afgevaardigden richting de GMR van 't Sticht
      </Typography>
    </div>
  );
}

export default Medezeggenschap;