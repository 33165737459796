import React from "react";
import Typography from "@mui/material/Typography";

function Groepsouders() {
  return (
    <div style={{ maxWidth: "720px", marginLeft: "10%" }}>
      <Typography variant="h5">Groepsouders</Typography>
      <Typography variant="body1" sx={{ marginBottom: 5 }}>
        De Windroos biedt een rijk onderwijsaanbod, maar daarvoor hebben wij wel
        de hulp van ouders nodig. Het koken met kinderen, het werken in de
        moestuin, het helpen tijdens de werkhoeken zijn onderdelen van ons
        onderwijs waarbij ouderhulp cruciaal is. Wij verwachten dat je op
        ouderavonden aanwezig bent en dat je met ons samen de ontwikkeling van
        jouw kind zorgvuldig blijft volgen. Ook bij het organiseren van feesten,
        zoals het Boekenfeest, Sinterklaas, het Lentefeest en het Zomerfeest,
        kunnen wij niet zonder hulp van ouders. Als je voor jouw kind kiest voor
        De Windroos, kies je ook als ouder voor deze school, waar
        ouderbetrokkenheid een gegeven is. Hierdoor wordt je als ouder actief
        onderdeel van de school en dat is een verrijkend perspectief.
      </Typography>

      <img
        src="https://wrassets.boss-tech.nl/beelden/perenfeest.JPG"
        alt="beeldgezondeschool"
        style={{ width: "100%", borderRadius: "5%" }}
        loading="lazy"
      />
    </div>
  );
}

export default Groepsouders;
