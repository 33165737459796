import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import S3 from "aws-sdk/clients/s3";

// const s3 = new S3();

// const getUrl = async (image) => {
// 	const signedUrl = await s3.getSignedUrl("getObject", {
// 		Bucket: "wrtest5bd4ee1c3fc94367a35d9e941742882e220646-staging",
// 		Key: { image },
// 	});

// 	console.log(signedUrl);

// 	// signedUrl can now be used to access the object
// };

// const image = getUrl("IMG_8541.jpg");

function Freinet() {
	return (
		<React.Fragment>
			<Container>
				<Grid
					container
					sx={{ marginTop: "15px", marginBottom: "15px" }}
					align="center"
					spacing={2}
				>
					<Grid xs={12} md={4} item={true}>
						<Card sx={{ maxWidth: 400, minHeight: 735 }}>
							<CardMedia
								component="img"
								image="https://wrassets.boss-tech.nl/KindenKijktafel.jpg"
								alt="eenschoolmeteenvisie"
								loading="lazy"
							></CardMedia>
							<CardContent>
								<Typography gutterBottom variant="h5" component="div">
									Een school met een visie
								</Typography>
								<Typography variant="body2" color="text.secondary">
									De Windroos is in 1979 opgericht door een groep betrokken
									ouders die hun kinderen op een andere nieuwe manier onderwijs
									wilden aanbieden. De pedagogische en onderwijskundige
									inzichten van Freinet en het Jenaplanonderwijs vormen de basis
									voor de manier waarop de Windroos een eigen vorm geeft aan het
									vernieuwingsonderwijs. Het is ervarings- en
									ontwikkelingsgericht onderwijs waarbij het werk van de
									leerlingen moet plaatsvinden in een voor hen zinvol verband.
									Met vernieuwingsonderwijs bereikt de Windroos dat elk kind
									zich op een eigen manier ontwikkelt en altijd in relatie tot
									de ander. Het kind krijgt de ruimte en de begeleiding om zelf
									te ontdekken en vragen te stellen. Het wordt uitgedaagd om
									eigen antwoorden te vinden en eigen keuzes te maken, daarin
									zowel ondersteund als ook gestimuleerd door de leerkrachten.
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid xs={12} md={4} item={true}>
						<Card sx={{ maxWidth: 400, minHeight: 735 }}>
							<CardMedia
								component="img"
								image="https://wrassets.boss-tech.nl/Levendrekenen2.jpg"
								alt="levend rekenen"
								loading="lazy"
							></CardMedia>
							<CardContent>
								<Typography gutterBottom variant="h5" component="div">
									Levend leren
								</Typography>
								<Typography variant="body2" color="text.secondary">
									Het onderwijs op de Windroos gaat uit van de ervaringen van
									kinderen: levend leren. Freinet en Jenaplan vormen de basis
									van dit onderwijs. De leerstof sluit aan bij de ervaringen van
									het kind, van de groep en bij de dagelijkse gebeurtenissen. De
									kring en werkhoeken zijn hierbij belangrijke werkvormen. Ook
									ouders spelen een belangrijke rol op de Windroos. De opvoeding
									op school vindt plaats in democratisch/ coöperatief overleg.
									De Windroos daagt kinderen uit en inspireert hen om kennis te
									verwerven.
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid xs={12} md={4} item={true}>
						<Card sx={{ maxWidth: 400, minHeight: 735 }}>
							<CardMedia
								component="img"
								image="https://wrassets.boss-tech.nl/werkhoeken3.jpg"
								alt="werkhoeken"
								loading="lazy"
							></CardMedia>
							<CardContent>
								<Typography gutterBottom variant="h5" component="div">
									Vernieuwingsonderwijs
								</Typography>
								<Typography variant="body2" color="text.secondary">
									Met vernieuwingsonderwijs richt de Windroos zich op een brede
									persoonlijke ontwikkeling van kinderen. Op die manier zorgen
									we voor een goede basis in cognitieve, sociaal-emotionele en
									creatieve zin voor aansluiting op het vervolgonderwijs.
									“Uitdagend onderwijs op de Windroos maakt dat kinderen
									geprikkeld worden, gemotiveerd zijn om zich te uiten, te leren
									en te ontwikkelen tot kritisch denkende burgers met oog voor
									hun omgeving,” Als vernieuwingsschool zijn we in ontwikkeling,
									reflecteren en reageren we als moderne school op actuele,
									bestendige ontwikkelingen in de maatschappij.
								</Typography>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Container>
		</React.Fragment>
	);
}

export default Freinet;

// <Card sx={{ display: "flex", margin: 4 }}>
// 	<Box sx={{ display: "flex", flexDirection: "column", width: "65%" }}>
// 		<CardContent sx={{ flex: "1 0 auto" }}>
// 			<Typography component="div" variant="h5">
// 				De Windroos: een school met een visie
// 			</Typography>
// 			<br></br>
// 			<Typography variant="body1" component="div">
// 				De Windroos is in 1979 opgericht door een groep betrokken ouders
// 				die hun kinderen op een andere nieuwe manier onderwijs wilden
// 				aanbieden. De pedagogische en onderwijskundige inzichten van
// 				Freinet en het Jenaplanonderwijs vormen de basis voor de manier
// 				waarop de Windroos een eigen vorm geeft aan het
// 				vernieuwingsonderwijs. Het is ervarings- en ontwikkelingsgericht
// 				onderwijs waarbij het werk van de leerlingen moet plaatsvinden in
// 				een voor hen zinvol verband. Met vernieuwingsonderwijs bereikt de
// 				Windroos dat elk kind zich op een eigen manier ontwikkelt en
// 				altijd in relatie tot de ander. Het kind krijgt de ruimte en de
// 				begeleiding om zelf te ontdekken en vragen te stellen. Het wordt
// 				uitgedaagd om eigen antwoorden te vinden en eigen keuzes te maken,
// 				daarin zowel ondersteund als ook gestimuleerd door de
// 				leerkrachten.
// 			</Typography>
// 		</CardContent>
// 	</Box>
// 	<CardMedia
// 		component="img"
// 		sx={{
// 			maxWidth: "35%",
// 			justifyContent: "flex-end",
// 			borderRadius: "0 10px 10px 0",
// 		}}
// 		image="https://source.unsplash.com/random/200x200?sig=5"
// 		alt="eenschoolmeteenvisie"
// 	/>
// </Card>
// <Card sx={{ display: "flex", margin: 4 }}>
// 	<CardMedia
// 		component="img"
// 		sx={{
// 			justifyContent: "flex-start",
// 			maxWidth: "35%",
// 			borderRadius: "0 10px 10px 0",
// 		}}
// 		image="https://source.unsplash.com/random/200x200?sig=6"
// 		alt="eenschoolmeteenvisie"
// 	/>
// 	<Box sx={{ display: "flex", flexDirection: "column", width: "65%" }}>
// 		<CardContent sx={{ flex: "1 0 auto" }}>
// 			<Typography component="div" variant="h5">
// 				Levend leren
// 			</Typography>
// 			<br></br>
// 			<Typography variant="body1" component="div">

// 			</Typography>
// 		</CardContent>
// 	</Box>
// </Card>
// <Card sx={{ display: "flex", margin: 4 }}>
// 	<Box sx={{ display: "flex", flexDirection: "column", width: "65%" }}>
// 		<CardContent sx={{ flex: "1 0 auto" }}>
// 			<Typography component="div" variant="h5">
// 				Vernieuwingsonderwijs
// 			</Typography>
// 			<br></br>
// 			<Typography variant="body1" component="div">

// 			</Typography>
// 		</CardContent>
// 	</Box>
// 	<CardMedia
// 		component="img"
// 		sx={{
// 			justifyContent: "flex-end",
// 			maxWidth: "35%",
// 			borderRadius: "0 10px 10px 0",
// 		}}
// 		image="https://source.unsplash.com/random/200x200?sig=7"
// 		alt="eenschoolmeteenvisie"
// 	/>
// </Card>
