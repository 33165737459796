import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Team from "./Team";
import GezondeSchool from "./GezondeSchool";
import Sticht from "./Sticht";
import Vacatures from "./Vacatures";
import Schoolgids from "./Schoolgids";
import InformatieDocumenten from "./InformatieDocumenten";

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

export default function Meerover() {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
				>
					<Tab label="Team" {...a11yProps(0)} />
					<Tab label="Gezonde school" {...a11yProps(1)} />
					<Tab label="'t Sticht'" {...a11yProps(2)} />
					<Tab label="Vacatures" {...a11yProps(3)} />
					<Tab label="Schoolgids en ondersteuningsplan" {...a11yProps(4)} />
					<Tab label="Informatie en documenten" {...a11yProps(5)} />
				</Tabs>
			</Box>
			<CustomTabPanel value={value} index={0}>
				<Team />
			</CustomTabPanel>
			<CustomTabPanel value={value} index={1}>
				<GezondeSchool />
			</CustomTabPanel>
			<CustomTabPanel value={value} index={2}>
				<Sticht />
			</CustomTabPanel>
			<CustomTabPanel value={value} index={3}>
				<Vacatures />
			</CustomTabPanel>
			<CustomTabPanel value={value} index={4}>
				<Schoolgids />
			</CustomTabPanel>
			<CustomTabPanel value={value} index={5}>
				<InformatieDocumenten />
			</CustomTabPanel>
		</Box>
	);
}
