import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Paper } from "@mui/material";

function Contact({ theme }) {
  return (
    <div>
      <Typography variant="h4" color="windroos">
        Contactinformatie
      </Typography>
      <Box sx={{ margin: 5 }}>
        <Paper elevation={3}>
          <Typography
            variant="h5"
            color="windroos"
            sx={{ paddingTop: 2, paddingLeft: 2 }}
          >
            Adresgegevens
          </Typography>
          <Typography
            variant="body1"
            color="windroos"
            sx={{ paddingTop: 2, paddingLeft: 2 }}
          >
            <strong>Basisschool De Windroos</strong>
          </Typography>
          <Typography
            variant="body1"
            color="windroos"
            sx={{ paddingLeft: 2, paddingBottom: 2 }}
          >
            Karolingersweg 147A
            <br />
            3962 AD Wijk bij Duurstede
            <br />
            Telefoon: 0343-592798
            <br />
            E-mail:{" "}
            <a href="mailto:vernieuwingsschool@windroos.nl">
              vernieuwingsschool@windroos.nl
            </a>
          </Typography>
        </Paper>
      </Box>
    </div>
  );
}

export default Contact;
