import React from "react";
import Typography from "@mui/material/Typography";

function Schoolgids() {
	return (
		<div style={{ maxWidth: "720px", marginLeft: "10%" }}>
			<Typography variant="h5">Schoolgids</Typography>
			<img
				src="https://wrassets.boss-tech.nl/beelden/zomerfeest.jpeg"
				alt="zomerfeest"
				style={{ width: "100%", borderRadius: "5%" }}
			/>
			<Typography variant="body1" sx={{ marginBottom: 16 }}>
				In onze Windroos{" "}
				<a
					href="https://wrassets.boss-tech.nl/documents/beleid/Schoolgids 2024-2025 de Windroos.pdf"
					target="_blank"
					rel="noopener noreferrer"
					download
				>
					schooldgids 2024-2025
				</a>
				, geeft de Windroos je een zo compleet mogelijk beeld van de gang van
				zaken op school. Scholen hebben verschillende kwaliteiten. In dit
				document kun je lezen hoe de Windroos dat doet, waar de school voor
				staat.
				<br />
				<br />
				Daarnaast zijn praktische zaken in de schoolgids opgenomen, zoals
				schooltijden, vakantieroosters e.d.
				<br />
				<br />
				Daarnaast ontvangen wij vanuit het samenwerkingsverband ZOUT
				ondersteuning bij ons(passend) onderwijs. Ons ondersteuningsprofiel vind
				je hier:
				<a
					href="https://wrassets.boss-tech.nl/documents/beleid/SOP Schoolondersteuningsprofiel 2023 2024.pdf"
					target="_blank"
					rel="noopener noreferrer"
					download
				>
					{" "}
					Ondersteuningsprofiel
				</a>
			</Typography>
		</div>
	);
}

export default Schoolgids;

// In onze Windroos schoolgids 2022 - 2023, geeft de Windroos je een zo compleet mogelijk beeld van de gang van zaken op school.Scholen verschillen in werken, in sfeer en in hoe kinderen er leren.

// Scholen hebben verschillende kwaliteiten.In dit document kun je lezen hoe de Windroos dat doet, waar de school voor staat.
// Daarnaast zijn praktische zaken in de schoolgids opgenomen, zoals schooltijdens, vakantieroosters e.d.

// Daarnaast ontvangen wij vanuit het samenwerkingsverband ZOUT ondersteuning bij ons(passend) onderwijs.Meer over het aanbod in dit kader vind je in het Ondersteuningsplan - 2022 - 2026 De Windroos.Ons ondersteuningsprofiel vind je hier: Ondersteuningsprofiel
