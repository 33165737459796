import React from "react";
import Typography from "@mui/material/Typography";

function Oudervereniging() {
  return (
    <div style={{ maxWidth: "720px", marginLeft: "10%" }}>
      <Typography variant="h5">Oudervereniging</Typography >
      <Typography variant="body1" sx={{ marginBottom: 16 }}>
        Iedere ouder/verzorger met kind(eren) op de Windroos is automatisch lid
        van de Oudervereniging ‘Vernieuwingsschool Wijk bij Duurstede’. De
        oudervereniging is betrokken bij de vieringen en activiteiten op de
        school en int de ouderbijdrage. Zij levert actief een bijdrage aan het
        ervaringsgerichte onderwijs op de Windroos, door bijvoorbeeld
        speelmaterialen te faciliteren, de Klasse!Kas mogelijk te maken en de
        vieringen in stand te houden. De oudervereniging wisselt ook regelmatig
        van gedachten met de MR over het reilen en zeilen in de school.
        <br />
        De oudervereniging bestaat uit:
        <br />
        <ul>
          <li>Lisanne Bernart (voorzitter)</li>
          <li>Arjan Syrier (penningmeester)</li>
          <li>Chantal Kok</li>
          <li>Ramona Mochel</li>
        </ul>
        Leden in de oudervereniging worden benoemd door alle ouders (in de
        ledenvergadering) voor een periode van steeds drie jaar. Voor meer
        informatie **EMAIL**
        <br></br>
        <br></br>
        De Oudervereniging int ook de jaarlijkse vrijwillige ouderbijdrage. Met
        de ouderbijdrage is de Windroos in staat om invulling te geven aan het
        bijzondere karakter van de school. De ouderbijdrage draagt namelijk bij
        aan: Het realiseren van bijzondere activiteiten zoals boekenfeest,
        sinterklaas, kerstfeest, lentefeest en zomerfeest; Het mogelijk maken
        dat kinderen zelfstandig en in eigen tempo kunnen werken – om dit te
        realiseren wordt er door leerkrachten veel extra materiaal gemaakt;
        Iedere klas in staat te1 stellen in te springen op de inbreng van
        kinderen ook als daar wat kosten voor gemaakt moeten worden; denk aan
        een uitstapje, aanschaf materiaal, voorstellingen; Communicatie, op
        velerlei manieren worden ouders en kinderen op de hoogte gehouden van de
        activiteiten op school: via de site, via nieuwsbrieven, de ouderkrant en
        de kinderkrant. De vrijwillige bijdrage is minimaal 67,50 euro per kind
        per jaar. Wij willen er nadrukkelijk op attenderen dat meer mag! In het
        najaar ontvangen ouders een verzoek tot betaling van de ouderbijdrage
        voor het betreffende schooljaar.
      </Typography>
    </div>
  );
}

export default Oudervereniging;