import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Container, Button } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

function Groepen() {
  //states per group (needs a wrapper in the future)
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  //modal handlers groep 1 en 2
  const handleOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  //modal handlers groep 3
  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  //modal handlers groep 4
  const handleOpen3 = () => {
    setOpen3(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  //modal handlers groep 5
  const handleOpen4 = () => {
    setOpen4(true);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  //modal handlers groep 6
  const handleOpen5 = () => {
    setOpen5(true);
  };

  const handleClose5 = () => {
    setOpen5(false);
  };

  //modal handlers groep 7
  const handleOpen6 = () => {
    setOpen6(true);
  };

  const handleClose6 = () => {
    setOpen6(false);
  };

  //modal handlers groep 8
  const handleOpen7 = () => {
    setOpen7(true);
  };

  const handleClose7 = () => {
    setOpen7(false);
  };

  return (
    <React.Fragment>
      <Container>
        <div style={{ width: "80%" }}>
          <Typography variant="h3">In de klas</Typography>
        </div>
        <div>
          <Grid container spacing={2} align="center">
            <Grid xs={12} md={4}>
              <Paper elevation={3} sx={{ margin: "3pt", minHeight: "550px" }}>
                <Typography variant="h4" color="initial">
                  Groep 1 en 2
                </Typography>
                <img
                  src="https://wrassets.boss-tech.nl/Werkhoeken.jpg"
                  alt="kleuters"
                  style={{ width: "100%", borderRadius: "5%" }}
                  loading="lazy"
                />
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{ padding: "10px" }}
                >
                  De kleuters werken vanuit thema’s, net als de andere groepen.
                  Zij spelen elke dag in de verschillende hoeken (spelenderwijs
                  leren zij iedere dag in de verschillende hoeken), die per
                  thema kunnen wisselen. In de kring vindt het overleg plaats en
                  worden verschillende activiteiten aangeboden.
                </Typography>
                <Button variant="text" onClick={handleOpen1}>
                  Lees meer
                </Button>
                <Dialog
                  open={open1}
                  onClose={handleClose1}
                  aria-labelledby="groep1en2"
                  aria-describedby="groep1en2"
                >
                  <DialogTitle id="groep1en2">Groep 1 en 2</DialogTitle>
                  <DialogContent>
                    <img
                      src="https://wrassets.boss-tech.nl/Werkhoeken.jpg"
                      alt="kleuters"
                      style={{
                        width: "100%",
                        maxHeight: "80%",
                        borderRadius: "5%",
                      }}
                      loading="lazy"
                    />
                    <DialogContentText id="groep1en2">
                      De kleuters werken vanuit thema’s, net als de andere
                      groepen. Zij spelen elke dag in de verschillende hoeken
                      (spelenderwijs leren zij iedere dag in de verschillende
                      hoeken), die per thema kunnen wisselen. In de kring vindt
                      het overleg plaats en worden verschillende activiteiten
                      aangeboden. Levend rekenen en levend taalonderwijs wordt
                      op een speelse manier binnen de thema’s aangeboden,
                      waarbij wordt aangesloten op de belevingswereld van het
                      jonge kind. Het zelf, geholpen door de leerkracht, op zoek
                      te gaan naar antwoorden, om vanuit ervaring te leren, is
                      de insteek gedurende een schooldag op De Windroos. Het
                      bedenken van een eigen tekst en het maken van een tekening
                      hierbij, begint bij ons al bij de kleuters. Het
                      voorbereidend lezen en rekenen krijgt binnen de thema’s
                      aandacht in deze groep. De kinderen spelen veel buiten en
                      ook hebben zij twee keer per week gym in onze speelzaal.
                      Alle kleuters hebben een maatje, dat is een kind uit groep
                      7 of 8. Dit maatje begeleidt de kleuter bij de
                      maandviering en tijdens feesten op school.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose1}>Sluiten</Button>
                  </DialogActions>
                </Dialog>
              </Paper>
            </Grid>
            <Grid xs={12} md={4}>
              <Paper elevation={3} sx={{ margin: "3pt", minHeight: "550px" }}>
                <Typography variant="h4" color="initial">
                  Groep 3
                </Typography>
                <img
                  src="https://wrassets.boss-tech.nl/beelden/indeklas/groep3_tekstenenthema.jpg"
                  alt="groep 3"
                  style={{
                    maxHeight: "250px",
                    maxWidth: "100%",
                    borderRadius: "5%",
                  }}
                  loading="lazy"
                />
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{ padding: "10px" }}
                >
                  In groep 3 begint het ‘echte leren’. De kinderen schrijven
                  vanaf deze groep elke week een eigen tekst, om van daaruit de
                  letters te leren, waarna het leren lezen begint. Binnen het
                  taalonderwijs maken we veel gebruik van de eigen teksten van
                  de kinderen.
                </Typography>
                <Button variant="text" onClick={handleOpen2}>
                  Lees meer
                </Button>
                <Dialog
                  open={open2}
                  onClose={handleClose2}
                  aria-labelledby="groep3"
                  aria-describedby="groep3"
                >
                  <DialogTitle id="groep3">Groep 3</DialogTitle>
                  <DialogContent>
                    <img
                      src="https://wrassets.boss-tech.nl/beelden/indeklas/groep3_tekstenenthema.jpg"
                      alt="groep 3 teksten"
                      style={{ width: "100%", borderRadius: "5%" }}
                      loading="lazy"
                    />
                    <DialogContentText id="groep3">
                      In groep 3 begint het ‘echte leren’. De kinderen schrijven
                      vanaf deze groep elke week een eigen tekst, om van daaruit
                      de letters te leren, waarna het leren lezen begint. Binnen
                      het taalonderwijs maken we veel gebruik van de eigen
                      teksten van de kinderen. Ook het rekenen start in groep 3,
                      eerst nog veelal met concreet materiaal om van daaruit
                      steeds meer uit een boek te gaan werken. Het leren
                      verloopt grotendeels binnen thema’s, die vanuit de
                      kinderen worden aangedragen, vaak gebaseerd op de verhalen
                      over hun ervaringen.
                      <br /> Elke week werken de kinderen twee keer in de
                      werkhoeken. Dit is dan vaak bouwen, tekenen, toneel spelen
                      of een andere verwerking binnen het thema dat op dat
                      moment wordt behandeld. <br />
                      Er wordt gewerkt met kringen. Zo is er de vergaderkring,
                      de weekendkring, de tekstenkring, de verjaardags-kring en
                      een thema-kring. De kring blijft de centrale plek voor
                      groepsgesprekken. Zo is er de weekendkring, de
                      tekstenkring waarin de kinderen hun teksten voorlezen en
                      bespreken, de verjaardagskring en een themakring. De groep
                      heeft iedere week een vergaderkring, hier worden
                      complimenten gegeven, vragen gesteld, plannen gesmeed en
                      de klassenafspraken gemaakt en geëvalueerd. Groep 3 heeft
                      gym in de gymzaal in de stad, gegeven door een
                      gymleerkracht.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose2}>Sluiten</Button>
                  </DialogActions>
                </Dialog>
              </Paper>
            </Grid>
            <Grid xs={12} md={4}>
              <Paper elevation={3} sx={{ margin: "3pt", minHeight: "550px" }}>
                <Typography variant="h4" color="initial">
                  Groep 4
                </Typography>
                <img
                  src="https://wrassets.boss-tech.nl/beelden/indeklas/groep4werken.jpg"
                  alt="kleuters"
                  style={{
                    maxHeight: "250px",
                    maxWidth: "100%",
                    borderRadius: "5%",
                  }}
                  loading="lazy"
                />
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{ padding: "10px" }}
                >
                  Het lezen en rekenen wordt steeds verder uitgebouwd. Ook het
                  werken in de thema’s wordt verder uitgediept, het lezen wordt
                  hier in verwerkt en de kinderen worden steeds zelfstandiger,
                  ook in het verwerken van de lesstof. Hierbij gebruiken zij een
                  weekplan.
                  <br />
                  <br />
                </Typography>
                <Button variant="text" onClick={handleOpen3}>
                  Lees meer
                </Button>
                <Dialog
                  open={open3}
                  onClose={handleClose3}
                  aria-labelledby="groep4"
                  aria-describedby="groep4"
                >
                  <DialogTitle id="groep4">Groep 4</DialogTitle>
                  <img
                    src="https://wrassets.boss-tech.nl/beelden/indeklas/groep4werken.jpg"
                    alt="groep3leesmethode"
                    style={{
                      maxHeight: "400px",
                      maxWidth: "100%",
                      borderRadius: "5%",
                    }}
                    loading="lazy"
                  />
                  <DialogContent id="groep4">
                    <DialogContentText id="groep4">
                      Het lezen en rekenen wordt steeds verder uitgebouwd. Ook
                      het werken in de thema’s wordt verder uitgediept, het
                      lezen wordt hier in verwerkt en de kinderen worden steeds
                      zelfstandiger, ook in het verwerken van de lesstof.
                      Hierbij gebruiken zij een weekplan.
                      <br />
                      <br /> Er wordt gewerkt met kringen. Zo is er de
                      vergaderkring, de weekendkring, de tekstenkring en een
                      thema-kring. In groep 4 worden twee keer per week de
                      werkhoeken aangeboden, waarbij de kinderen met hun handen
                      dingen gaan maken die betrekking hebben op het thema.
                      Groep 4 heeft gym in de gymzaal in de stad, gegeven door
                      een gymleerkracht.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose3}>Sluiten</Button>
                  </DialogActions>
                </Dialog>
              </Paper>
            </Grid>
            <Grid xs={12} md={4}>
              <Paper elevation={3} sx={{ margin: "3pt", minHeight: "550px" }}>
                <Typography variant="h4" color="initial">
                  Groep 5
                </Typography>
                <img
                  src="https://wrassets.boss-tech.nl/beelden/indeklas/Groep 5 zelfstandig werken.jpg"
                  alt="groep 5 zelfstandig werkschema"
                  style={{
                    maxHeight: "260px",
                    maxWidth: "100%",
                    borderRadius: "5%",
                  }}
                  loading="lazy"
                />
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{ padding: "10px" }}
                >
                  Het vergaderen wordt vanaf groep 1 geoefend tijdens
                  vergaderkringen. Maar er zijn ook nog andere kring-momenten om
                  samen te komen en te overleggen, zoals de weekendkring, de
                  tekstenkring, de verjaardags-kring en een thema-kring.
                  <br />
                </Typography>
                <Button variant="text" onClick={handleOpen4}>
                  Lees meer
                </Button>
                <Dialog
                  open={open4}
                  onClose={handleClose4}
                  aria-labelledby="groep5"
                  aria-describedby="groep5"
                >
                  <DialogTitle id="groep5">Groep 5</DialogTitle>
                  <img
                    src="https://wrassets.boss-tech.nl/beelden/indeklas/Groep 5 zelfstandig werken.jpg"
                    alt="groep 5 zelfstandig werkschema"
                    style={{ width: "100%", borderRadius: "5%" }}
                    loading="lazy"
                  />
                  <DialogContent id="groep5">
                    <DialogContentText id="groep5">
                      Het vergaderen wordt vanaf groep 1 geoefend tijdens
                      vergaderkringen. Maar er zijn ook nog andere
                      kring-momenten om samen te komen en te overleggen, zoals
                      de weekendkring, de tekstenkring, de verjaardags-kring en
                      een thema-kring.
                      <br />
                      <br /> Het lezen en rekenen wordt steeds verder
                      uitgebouwd. Binnen het werken in de thema’s krijgen de
                      kinderen meer ruimte om hun eigen leervragen te
                      onderzoeken en verwerken. Die informatie komt weer samen
                      in de kring en de kennis wordt aangevuld met de leeslessen
                      (Close Reading) in het thema. De tekstopdrachten worden
                      vaker gekoppeld aan het thema. Groep 5 heeft iedere week
                      werkhoeken. Groep 5 heeft gym in de gymzaal in de stad,
                      gegeven door een gymleerkracht.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose4}>Sluiten</Button>
                  </DialogActions>
                </Dialog>
              </Paper>
            </Grid>
            <Grid xs={12} md={4}>
              <Paper elevation={3} sx={{ margin: "3pt", minHeight: "550px" }}>
                <Typography variant="h4" color="initial">
                  Groep 6
                </Typography>
                <img
                  src="https://wrassets.boss-tech.nl/beelden/indeklas/zelfwerkpakken .jpg"
                  alt="groep 6 zelf werk pakken"
                  style={{
                    maxHeight: "260px",
                    maxWidth: "100%",
                    borderRadius: "5%",
                  }}
                  loading="lazy"
                />
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{ padding: "10px" }}
                >
                  In groep 6 krijgen de kinderen meer zelfstandig werk dat zij
                  zelfstandig inplannen in hun weekplan. Hierbij leren zij
                  steeds meer zelf keuzes te maken en verantwoordelijkheid te
                  dragen voor hun eigen leerproces.
                  <br />
                  <br />
                </Typography>
                <Button variant="text" onClick={handleOpen5}>
                  Lees meer
                </Button>
                <Dialog
                  open={open5}
                  onClose={handleClose5}
                  aria-labelledby="groep6"
                  aria-describedby="groep6"
                >
                  <DialogTitle id="groep6">Groep 6</DialogTitle>
                  <img
                    src="https://wrassets.boss-tech.nl/beelden/indeklas/zelfwerkpakken .jpg"
                    alt="groep 6 zelf werk pakken"
                    style={{
                      maxHeight: "450px",
                      maxWidth: "100%",
                      borderRadius: "5%",
                    }}
                    loading="lazy"
                  />
                  <DialogContent id="groep6">
                    <DialogContentText id="groep6">
                      In groep 6 krijgen de kinderen meer zelfstandig werk dat
                      zij zelfstandig inplannen in hun weekplan. Hierbij leren
                      zij steeds meer zelf keuzes te maken en
                      verantwoordelijkheid te dragen voor hun eigen leerproces.
                      Het vergaderen wordt steeds meer van de kinderen zelf,
                      waarbij de leerkracht een minder sturende rol krijgt en
                      meer een coachende taak uitvoert tijdens deze
                      overlegmomenten.
                      <br />
                      <br /> Er is een weekendkring, een tekstenkring, een
                      verjaardagskring en een thema-kring. Het lezen en rekenen
                      wordt steeds verder uitgebouwd, zoveel mogelijk ingebed in
                      het gekozen thema. Ook het werken met thema’s krijgt meer
                      verdieping, doordat kinderen steeds zelfstandiger worden,
                      ook in het verwerken van lesstof en in het werken binnen
                      een thema, mede door het doen van een studie. Vanaf groep
                      6 krijgen de kinderen Engels en topografie over Nederland.
                      Ook hebben de kinderen gym in de gymzaal in de stad,
                      gegeven door een gymleerkracht.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose5}>Sluiten</Button>
                  </DialogActions>
                </Dialog>
              </Paper>
            </Grid>
            <Grid xs={12} md={4}>
              <Paper elevation={3} sx={{ margin: "3pt", minHeight: "550px" }}>
                <Typography variant="h4" color="initial">
                  Groep 7
                </Typography>
                <img
                  src="https://wrassets.boss-tech.nl/beelden/indeklas/begeleidingzelfwerken78.jpg"
                  alt="kleuters"
                  style={{ width: "100%", borderRadius: "5%" }}
                  loading="lazy"
                />
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{ padding: "10px" }}
                >
                  In groep 7 krijgen de kinderen meer zelfwerk dat zij inplannen
                  op hun werkplan. Hierbij leren zij steeds meer zelf keuzes te
                  maken en verantwoordelijkheid te dragen voor hun eigen
                  leerproces.
                  <br />
                  <br />
                </Typography>
                <Button variant="text" onClick={handleOpen6}>
                  Lees meer
                </Button>
                <Dialog
                  open={open6}
                  onClose={handleClose6}
                  aria-labelledby="groep7"
                  aria-describedby="groep7"
                >
                  <DialogTitle id="groep7">Groep 7</DialogTitle>
                  <img
                    src="https://wrassets.boss-tech.nl/beelden/indeklas/begeleidingzelfwerken78.jpg"
                    alt="kleuters"
                    style={{ width: "100%", borderRadius: "5%" }}
                    loading="lazy"
                  />
                  <DialogContent id="groep7">
                    <DialogContentText id="groep7">
                      In groep 7 krijgen de kinderen meer zelfwerk dat zij
                      inplannen op hun werkplan. Hierbij leren zij steeds meer
                      zelf keuzes te maken en verantwoordelijkheid te dragen
                      voor hun eigen leerproces.
                      <br />
                      <br /> Het vergaderen wordt steeds meer van de kinderen
                      zelf, waarbij de leerkracht een minder sturende rol krijgt
                      en meer een coachende taak uitvoert tijdens deze
                      overlegmomenten. Er is een weekendkring, een tekstenkring,
                      een verjaardagskring en een thema-kring. Het lezen en
                      rekenen wordt steeds verder uitgebouwd. Ook het werken met
                      thema’s krijgt meer verdieping, doordat kinderen steeds
                      zelfstandiger worden, ook in het verwerken van lesstof en
                      in het werken binnen een thema. Het doen van studies over
                      een bepaald onderwerp wordt dit leerjaar verder
                      uitgebouwd. Groep 7 (en groep 8) gaat op kamp en de
                      kinderen worden steeds meer aangesproken op hun eigen
                      verantwoordelijkheid, op weg naar de middelbare school.
                      Alle kleuters hebben een maatje, dat is een kind uit groep
                      7 of 8. Dit maatje begeleidt de kleuter bij de
                      maandviering en tijdens feesten op school. Groep 7 heeft
                      gym in de gymzaal in de stad, gegeven door een
                      gymleerkracht.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose6}>Sluiten</Button>
                  </DialogActions>
                </Dialog>
              </Paper>
            </Grid>
            <Grid xs={12} md={4}>
              <Paper elevation={3} sx={{ margin: "3pt", minHeight: "550px" }}>
                <Typography variant="h4" color="initial">
                  Groep 8
                </Typography>
                <img
                  src="https://wrassets.boss-tech.nl/beelden/uitdaging groep8.jpeg"
                  alt="groep 8 werkt aan uitdaging"
                  style={{ width: "100%", borderRadius: "5%" }}
                  loading="lazy"
                />
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{ padding: "10px" }}
                >
                  In groep 8 krijgen de kinderen meer zelfwerk dat zij inplannen
                  op hun werkplan. Hierbij leren zij steeds meer zelf keuzes te
                  maken en verantwoordelijkheid te dragen voor hun eigen
                  leerproces.
                  <br />
                  <br />
                </Typography>
                <Button variant="text" onClick={handleOpen7}>
                  Lees meer
                </Button>
                <Dialog
                  open={open7}
                  onClose={handleClose7}
                  aria-labelledby="groep8"
                  aria-describedby="groep8"
                >
                  <DialogTitle id="groep8">Groep 8</DialogTitle>
                  <img
                    src="https://wrassets.boss-tech.nl/beelden/uitdaging groep8.jpeg"
                    alt="groep 8 werkt aan uitdaging"
                    style={{ width: "100%", borderRadius: "5%" }}
                    loading="lazy"
                  />
                  <DialogContent id="groep8">
                    <DialogContentText id="groep8">
                      In groep 8 krijgen de kinderen meer zelfwerk dat zij
                      inplannen op hun werkplan. Hierbij leren zij steeds meer
                      zelf keuzes te maken en verantwoordelijkheid te dragen
                      voor hun eigen leerproces.
                      <br />
                      <br /> Het vergaderen wordt steeds meer van de kinderen
                      zelf, waarbij de leerkracht een minder sturende rol krijgt
                      en meer een coachende taak uitvoert tijdens deze
                      overlegmomenten. Er is een weekendkring, een tekstenkring,
                      een verjaardags-kring en een thema-kring. Alle kleuters
                      hebben een maatje, dat is een kind uit groep 7 of 8. Dit
                      maatje begeleidt de kleuter bij de maandviering of tijdens
                      feesten op school. Groep 8 is het afsluitende jaar op de
                      basisschool. Ieder kind kiest een eigen uitdaging om aan
                      te werken voordat het naar de middelbare school gaat. Om
                      alvast te ervaren hoe het op de middelbare school zal
                      zijn, hebben we twee VO-weken, waarbij de kinderen per uur
                      een andere les van een andere docent krijgen. Ook gaat
                      groep 8 (met groep 7) op kamp en is er een afscheidsavond
                      die de kinderen zoveel mogelijk zelf vormgeven.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose7}>Sluiten</Button>
                  </DialogActions>
                </Dialog>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default Groepen;
