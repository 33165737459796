import React from "react";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";

function Sticht() {
	return (
		<div style={{ maxWidth: "720px", marginLeft: "10%" }}>
			<Typography variant="h5">Het Sticht</Typography>
			<img
				src="https://wrassets.boss-tech.nl/stichtLogo.png"
				alt="logosticht"
				style={{ width: "75%", marginBottom: 16 }}
			/>
			<Typography variant="body1" sx={{ marginBottom: 4 }}>
				Het Sticht is de overkoepelende stichting voor acht basisscholen in de
				gemeenten Zeist, Baarn, Utrechtse Heuvelrug en Wijk bij Duurstede: zeven
				katholieke scholen en één algemeen bijzondere school. Wij willen
				kinderen laten ontdekken wie ze zijn zodat ze later de juiste keuzes
				kunnen maken. Vaardigheden als communiceren, samenwerken en kritisch
				leren nadenken, vinden wij minstens zo belangrijk als hun cognitieve
				ontwikkeling. Onderwijs geven doen we samen met ouders/verzorgers en
				alle andere partners om het kind heen. Vandaar Samen scholen, samen
				leven.
			</Typography>
			<Typography variant="body1" sx={{ marginBottom: 4 }}>
				Het Sticht is ook verantwoordelijk voor kwaliteit en de de afhandeling
				van klachten. Voor de klachtenregeling van het Sticht, volg je deze{" "}
				<a
					href="https://www.hetsticht.nl/klachten"
					target="_blank"
					rel="noopener noreferrer"
				>
					link
				</a>
			</Typography>
			<Typography variant="body1" sx={{ marginBottom: 4 }}>
				Het Sticht is daarnaast verantwoordelijk voor een aantal beleidsdomeinen
				die voor alle aangesloten scholen gelden:
			</Typography>
			<a
				href="https://wrassets.boss-tech.nl/documents/sticht/Gedragscode-Het-Sticht-.pdf"
				target="_blank"
				rel="noopener noreferrer"
				download
			>
				<Button variant="text">Download gedragscode Het Sticht</Button>
			</a>
			<a
				href="https://wrassets.boss-tech.nl/documents/sticht/Protocol-Internet-Social-media.pdf"
				target="_blank"
				rel="noopener noreferrer"
				download
			>
				<Button variant="text">
					Download protocol internet en sociale media
				</Button>
			</a>
			<a
				href="https://wrassets.boss-tech.nl/documents/sticht/IBP-beleidsplan-2022.pdf"
				target="_blank"
				rel="noopener noreferrer"
				download
			>
				<Button variant="text">
					Download Informatiebeveiligings- en privacybeleid
				</Button>
			</a>
			<a
				href="https://wrassets.boss-tech.nl/documents/sticht/Privacyreglement-Het-Sticht.pdf"
				target="_blank"
				rel="noopener noreferrer"
				download
			>
				<Button variant="text">Download Privacyreglement Het Sticht</Button>
			</a>
			<a
				href="https://wrassets.boss-tech.nl/documents/beleid/Beleidsplan sociale veiligheid 2023 2024.pdf"
				target="_blank"
				rel="noopener noreferrer"
				download
			>
				<Button variant="text">
					Download het beleidsplan sociale veiligheid
				</Button>
			</a>
		</div>
	);
}

export default Sticht;
