/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDocumenten = /* GraphQL */ `
  query GetDocumenten($id: ID!) {
    getDocumenten(id: $id) {
      id
      Type
      Categorie
      Titel
      Link
      DateAdded
      DateAdjusted
      OtherInformation
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDocumentens = /* GraphQL */ `
  query ListDocumentens(
    $filter: ModelDocumentenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Type
        Categorie
        Titel
        Link
        DateAdded
        DateAdjusted
        OtherInformation
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNieuwsberichten = /* GraphQL */ `
  query GetNieuwsberichten($id: ID!) {
    getNieuwsberichten(id: $id) {
      id
      Titel
      Nieuwsbericht
      Afbeelding
      Creatiedatum
      Publicatiedatum
      Einddatum
      CreatedAt
      UpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNieuwsberichtens = /* GraphQL */ `
  query ListNieuwsberichtens(
    $filter: ModelNieuwsberichtenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNieuwsberichtens(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Titel
        Nieuwsbericht
        Afbeelding
        Creatiedatum
        Publicatiedatum
        Einddatum
        CreatedAt
        UpdatedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCreateAgendaItems = /* GraphQL */ `
  query GetCreateAgendaItems($id: ID!) {
    getCreateAgendaItems(id: $id) {
      id
      AgendaTitel
      Agendabeschrijving
      Datum
      Afbeelding
      Maand
      Jaar
      Periode
      CreateAt
      UpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCreateAgendaItems = /* GraphQL */ `
  query ListCreateAgendaItems(
    $filter: ModelCreateAgendaItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCreateAgendaItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        AgendaTitel
        Agendabeschrijving
        Datum
        Afbeelding
        Maand
        Jaar
        Periode
        CreateAt
        UpdatedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
