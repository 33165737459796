import { Typography } from "@mui/material";
import React from "react";

function NotFound() {
  return (
    <div>
      <Typography variant="h1" align="center">
        404 :-( <br></br>Oeps.... deze pagina lijkt niet te bestaan... misschien
        wordt die nog gemaakt
      </Typography>
    </div>
  );
}

export default NotFound;