import React from "react";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

function Visie() {
    return (
        <React.Fragment>
            <Container>
                <Typography
                    sx={{ marginTop: "15px", marginBottom: "15px" }}
                    variant="h4"
                    align="center">
                    Visie: op een natuurlijke manier tot leren komen...
                </Typography>
                <Typography>
                    We werken vanuit onze Freinet-visie. Wij werken vanuit thema’s die we vormgeven binnen ons taalonderwijs (het schrijven van teksten), binnen wereldoriëntatie, creatieve vorming en kringgesprekken. Kinderen schrijven elke week een eigen tekst en maken hier een creatieve verwerking bij. Wij willen kinderen nieuwsgierig maken en  ervaringen laten opdoen, waardoor zij op een natuurlijke manier tot leren komen. Mede tijdens het werken in de werkhoeken krijgt dit rijke onderwijsaanbod vorm: toneel spelen, bouwen in de kuil, werken in de moestuin, tekenen, eten koken, het werken aan een studie. Dit ervaringsgerichte leren vormt tevens de basis voor ons burgerschapsonderwijs, beschreven in het document ‘Ervaringsgericht leren en (wereld-) burgerschap.
                    Door onze manier van werken leren kinderen zelfstandig aan de slag te gaan, veelal in samenwerking met andere kinderen. Wij stimuleren kinderen om actief deel te nemen aan het onderwijsaanbod dat deels met de kinderen wordt vormgegeven.
                    <br /><br />
                </Typography>
                <Grid
                    container
                    sx={{ marginTop: "15px", marginBottom: "15px" }}
                    align="center"
                    spacing={3}
                >
                    <Grid xs={12} md={4} spacing={2}>
                        <Paper sx={{ minHeight: 300, margin: "2pt" }} elevation={3}>
                            <Typography sx={{ padding: 5 }} variant="body1" align="center">
                                “De focus van de ontwikkeling van een kind moet niet alleen op
                                cognitieve vaardigheden liggen, maar in balans zijn met het
                                ontwikkelen van de creatieve en sociale vaardigheden van ieder
                                individu"<br /><br />
                            </Typography>
                            <Paper sx={{ maxHeight: "275px" }} zDepth={1} circle={true} style={{ overflow: 'hidden' }}>
                                <img
                                    src="https://wrassets.boss-tech.nl/Werkhoeken2.jpg"
                                    alt="werken in de werkhoeken"
                                    style={{ width: "110%" }} />
                            </Paper>
                        </Paper>
                    </Grid>
                    <Grid xs={12} md={4}>
                        <Paper sx={{ minHeight: 300, margin: "2pt" }} elevation={3}>
                            <Typography sx={{ padding: 5 }} variant="body1" align="center">
                                “Ouders, begeleiders en verzorgers moeten betrokken worden en
                                een onderdeel/partner zijn van het leerproces en de ontwikkeling
                                van een kind.”<br /><br /><br />
                            </Typography>
                            <Paper sx={{ maxHeight: "275px" }} zDepth={1} circle={true} style={{ overflow: 'hidden' }}>
                                <img
                                    src="https://wrassets.boss-tech.nl/WO_boeken.jpg"
                                    alt="werken in de werkhoeken"
                                    style={{ width: "110%" }} />
                            </Paper>
                        </Paper>
                    </Grid>
                    <Grid xs={12} md={4}>
                        <Paper sx={{ minHeight: 300, margin: "2pt" }} elevation={3}>
                            <Typography sx={{ padding: 5 }} variant="body1" align="center">
                                “Een kind handelt, doet ontdekkingen en leert deze onder woorden
                                te brengen. Wanneer je alleen focust op goed presteren bij
                                toetsen e.d. ontwikkelt een kind niet optimaal. De ontwikkeling
                                tot een kritische burger begint nu al.”
                            </Typography>
                            <Paper sx={{ maxHeight: "275px" }} zDepth={1} circle={true} style={{ overflow: 'hidden' }}>
                                <img
                                    src="https://wrassets.boss-tech.nl/Boekenkast.jpg"
                                    alt="werken in de werkhoeken"
                                    style={{ width: "100%" }} />
                            </Paper>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}

export default Visie;