/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDocumenten = /* GraphQL */ `
  mutation CreateDocumenten(
    $input: CreateDocumentenInput!
    $condition: ModelDocumentenConditionInput
  ) {
    createDocumenten(input: $input, condition: $condition) {
      id
      Type
      Categorie
      Titel
      Link
      DateAdded
      DateAdjusted
      OtherInformation
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDocumenten = /* GraphQL */ `
  mutation UpdateDocumenten(
    $input: UpdateDocumentenInput!
    $condition: ModelDocumentenConditionInput
  ) {
    updateDocumenten(input: $input, condition: $condition) {
      id
      Type
      Categorie
      Titel
      Link
      DateAdded
      DateAdjusted
      OtherInformation
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDocumenten = /* GraphQL */ `
  mutation DeleteDocumenten(
    $input: DeleteDocumentenInput!
    $condition: ModelDocumentenConditionInput
  ) {
    deleteDocumenten(input: $input, condition: $condition) {
      id
      Type
      Categorie
      Titel
      Link
      DateAdded
      DateAdjusted
      OtherInformation
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNieuwsberichten = /* GraphQL */ `
  mutation CreateNieuwsberichten(
    $input: CreateNieuwsberichtenInput!
    $condition: ModelNieuwsberichtenConditionInput
  ) {
    createNieuwsberichten(input: $input, condition: $condition) {
      id
      Titel
      Nieuwsbericht
      Afbeelding
      Creatiedatum
      Publicatiedatum
      Einddatum
      CreatedAt
      UpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNieuwsberichten = /* GraphQL */ `
  mutation UpdateNieuwsberichten(
    $input: UpdateNieuwsberichtenInput!
    $condition: ModelNieuwsberichtenConditionInput
  ) {
    updateNieuwsberichten(input: $input, condition: $condition) {
      id
      Titel
      Nieuwsbericht
      Afbeelding
      Creatiedatum
      Publicatiedatum
      Einddatum
      CreatedAt
      UpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNieuwsberichten = /* GraphQL */ `
  mutation DeleteNieuwsberichten(
    $input: DeleteNieuwsberichtenInput!
    $condition: ModelNieuwsberichtenConditionInput
  ) {
    deleteNieuwsberichten(input: $input, condition: $condition) {
      id
      Titel
      Nieuwsbericht
      Afbeelding
      Creatiedatum
      Publicatiedatum
      Einddatum
      CreatedAt
      UpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCreateAgendaItems = /* GraphQL */ `
  mutation CreateCreateAgendaItems(
    $input: CreateCreateAgendaItemsInput!
    $condition: ModelCreateAgendaItemsConditionInput
  ) {
    createCreateAgendaItems(input: $input, condition: $condition) {
      id
      AgendaTitel
      Agendabeschrijving
      Datum
      Afbeelding
      Maand
      Jaar
      Periode
      CreateAt
      UpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCreateAgendaItems = /* GraphQL */ `
  mutation UpdateCreateAgendaItems(
    $input: UpdateCreateAgendaItemsInput!
    $condition: ModelCreateAgendaItemsConditionInput
  ) {
    updateCreateAgendaItems(input: $input, condition: $condition) {
      id
      AgendaTitel
      Agendabeschrijving
      Datum
      Afbeelding
      Maand
      Jaar
      Periode
      CreateAt
      UpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCreateAgendaItems = /* GraphQL */ `
  mutation DeleteCreateAgendaItems(
    $input: DeleteCreateAgendaItemsInput!
    $condition: ModelCreateAgendaItemsConditionInput
  ) {
    deleteCreateAgendaItems(input: $input, condition: $condition) {
      id
      AgendaTitel
      Agendabeschrijving
      Datum
      Afbeelding
      Maand
      Jaar
      Periode
      CreateAt
      UpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
