import React from "react";
import GitInfo from "react-git-info/macro";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

function Version() {
	return (
		<div>
			<Container maxWidth="xs">
				<Typography variant="h1" component="h2" gutterBottom>
					Version
				</Typography>
				<Typography variant="body1">
					<ul>
						<li>UI Version: {versiondata.ui_version}</li>
						<li>Backend Version: {versiondata.backend_version}</li>
						<li>Version Date: {versiondata.version_date}</li>
						<li>Deployment Date: {versiondata.deployment_date}</li>
						<li>Commit: {versiondata.commit}</li>
					</ul>
				</Typography>
			</Container>
		</div>
	);
}

export default Version;

const gitInfo = GitInfo();
const versiondata = {
	ui_version: "1.0.4",
	backend_version: "1.1.2",
	version_date: "2024-08-25",
	deployment_date: gitInfo.commit.date,
	commit: gitInfo.commit.shortHash,
};
