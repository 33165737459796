import React from "react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Item from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

function Homepage({ theme }) {
  return (
    <React.Fragment>
      <Container>
        <Box>
          <Stack>
            <Item>
              <Paper
                sx={{
                  marginTop: "20pt",
                  marginBottom: "20pt",
                  backgroundColor: "#EF7D00",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ padding: "16pt" }}
                  align="center"
                >
                  Op de Windroos ontdekt en leert ieder kind vanuit eigen
                  ervaring en in samenwerking met andere leerlingen,
                  leerkrachten en betrokken ouders over de buitenwereld, de
                  eigen omgeving en het eigen kunnen.
                </Typography>
              </Paper>
            </Item>
          </Stack>
        </Box>

        <Grid
          container
          sx={{ marginTop: "10px", marginBottom: "10px" }}
          align="center"
          spacing={3}
        >
          <Grid xs={12} sm={4}>
            <Card sx={{ maxWidth: 345, minHeight: 450 }}>
              <CardMedia
                sx={{ height: 300 }}
                image="https://wrassets.boss-tech.nl/Samenwerken.jpg"
                title="samenwerken"
                loading="lazy"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Samen!
                </Typography>
                <Typography
                  sx={{ minHeight: 70 }}
                  variant="body2"
                  color="text.secondary"
                >
                  Leerlingen, leerkrachten, ouders en betrokkenen werken nauw
                  samen voor een optimale ontwikkeling van het kind.
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  component={Link}
                  to="/freinet"
                  sx={{ color: `${theme.palette.rood.main}` }}
                  size="small"
                >
                  Lees meer..
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid xs={12} sm={4}>
            <Card sx={{ maxWidth: 345, minHeight: 450 }}>
              <CardMedia
                sx={{ height: 300 }}
                image="https://wrassets.boss-tech.nl/Zelfstandigwerken1.jpg"
                title="leren, zelfstandig groeien"
                loading="lazy"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Leren!
                </Typography>
                <Typography
                  sx={{ minHeight: 70 }}
                  variant="body2"
                  color="text.secondary"
                >
                  De basis van het onderwijs. Creatief nadenken en ontdekken, om
                  vervolgens hiervan te leren.
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  component={Link}
                  to="/visie"
                  sx={{ color: `${theme.palette.rood.main}` }}
                  size="small"
                >
                  Lees meer...
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid xs={12} sm={4}>
            <Card sx={{ maxWidth: 345, minHeight: 450 }}>
              <CardMedia
                sx={{ height: 300 }}
                image="https://wrassets.boss-tech.nl/Begeleiding.jpg"
                title="Helpen groeien"
                loading="lazy"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Groeien!
                </Typography>
                <Typography
                  sx={{ minHeight: 70 }}
                  variant="body2"
                  color="text.secondary"
                >
                  Ieder individu groeit door zich te ontwikkelen. Niet alleen in
                  cognitieve vaardigheden, maar ook in creatieve,
                  sociaal-emotionele en motorische vaardigheden.
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  component={Link}
                  to="/groepen"
                  sx={{ color: `${theme.palette.rood.main}` }}
                  size="small"
                >
                  Lees meer...
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default Homepage;
