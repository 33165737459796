import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

function Introduction() {
	const navigate = useNavigate();
	const handleClick = () => {
		navigate("/homepage");
	};

	return (
		<div>
			<Button variant="text" onClick={handleClick}>
				Direct naar de webpagina
			</Button>
			<br />
			<video width="60%" controls>
				<source
					src="https://wrassets.boss-tech.nl/Promo - De Windroos_V3_small.mp4"
					type="video/mp4"
				/>
			</video>
		</div>
	);
}

export default Introduction;
