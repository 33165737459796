import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .10)'
            : 'rgba(239, 125, 0, 1)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '2px solid rgba(23, 23, 23, .125)',
}));

export default function Klas() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div>
            <br />
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography color="black">Groep 1/2 - Kleutertijd op de Windroos</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <img src="https://source.unsplash.com/random/200x200?sig=15" alt="kleuter" />
                    <Typography>
                        De kleuters werken vanuit thema’s, net als de andere groepen.
                        Zij spelen elke dag in de verschillende hoeken, die per thema
                        kunnen wisselen. In de kring vindt het overleg plaats en worden
                        verschillende activiteiten aangeboden. Levend rekenen en levend
                        taalonderwijs wordt op een speelse manier binnen de thema’s aangeboden,
                        waarbij wordt aangesloten op de belevingswereld van het jonge kind.
                        Het zelf, geholpen door de leerkracht, op zoek te gaan naar antwoorden,
                        om vanuit ervaring te leren, is de insteek gedurende een schooldag op
                        De Windroos. Het bedenken van een eigen tekst en het maken van een
                        tekening hierbij, begint bij ons al bij de kleuters. Het voorbereidend
                        lezen en rekenen krijgt binnen de thema’s aandacht in deze groep.
                        De kinderen spelen veel buiten en ook hebben zij twee keer per week
                        gym in onze speelzaal.<br />
                        Alle kleuters hebben een maatje, dat is een kind uit groep 7 of 8.
                        Dit maatje begeleidt de kleuter bij de maandviering en tijdens feesten
                        op school.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography color="black">Groep 3</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <img src="https://source.unsplash.com/random/200x200?sig=19" alt="groep3" />
                    <Typography>
                        In groep 3 begint het ‘echte leren’. De kinderen schrijven vanaf deze groep elke week een eigen tekst, om van daaruit de letters te leren, waarna het leren lezen begint. Ook het rekenen start in groep 3, eerst nog veelal met concreet materiaal om van daaruit steeds meer uit een boek te gaan werken.
                        Het leren verloopt grotendeels binnen thema’s, die vanuit de kinderen worden aangedragen. Elke week mogen de kinderen twee keer in de werkhoeken werken. Dit is dan vaak bouwen, tekenen, toneel spelen of een andere verwerking binnen het thema dat op dat moment wordt behandeld.
                        Er wordt gewerkt met kringen. Zo is er de vergaderkring, de weekendkring, de tekstenkring, de verjaardags-kring en een thema-kring.
                        Groep 3 heeft gym in de gymzaal in de stad, gegeven door een gymleerkracht.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography color="black">Groep 4</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <img src="https://source.unsplash.com/random/200x200?sig=10" alt="groep3" />
                    <Typography>
                        Het lezen en rekenen wordt steeds verder uitgebouwd. Ook het werken met thema’s krijgt meer verdieping, doordat kinderen steeds zelfstandig worden, ook in het verwerken van lesstof en in het werken binnen een thema. Er wordt gewerkt met kringen. Zo is er de vergaderkring, de weekendkring, de tekstenkring en een thema-kring. In groep 4 worden twee keer per week de werkhoeken aangeboden, waarbij de kinderen met hun handen dingen gaan maken die betrekking hebben op het thema.
                        Groep 4 heeft gym in de gymzaal in de stad, gegeven door een gymleerkracht.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography color="black">Groep 5</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <img src="https://source.unsplash.com/random/200x200?sig=18" alt="groep3" />

                    <Typography>
                        Het vergaderen wordt vanaf groep 1 geoefend tijdens vergaderkringen. Maar er zijn ook nog andere kring-momenten om samen te komen en te overleggen, zoals de weekendkring, de tekstenkring, de verjaardags-kring en een thema-kring.
                        Het lezen en rekenen wordt steeds verder uitgebouwd. Ook het werken met thema’s krijgt meer verdieping, doordat kinderen steeds zelfstandig worden, ook in het verwerken van lesstof en in het werken binnen een thema.
                        Groep 5 heeft gym in de gymzaal in de stad, gegeven door een gymleerkracht.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                    <Typography color="black">Groep 6</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <img src="https://source.unsplash.com/random/200x200?sig=55" alt="groep3" />

                    <Typography>
                        In groep 6 krijgen de kinderen meer zelfstandig werk dat zij inplannen op hun werkplan. Hierbij leren zij steeds meer zelf keuzes te maken en verantwoordelijkheid te dragen voor hun eigen leerproces.
                        Het vergaderen wordt steeds meer van de kinderen zelf, waarbij de leerkracht een minder sturende rol krijgt en meer een coachende taak uitvoert tijdens deze overlegmomenten. Er is een weekendkring, een tekstenkring, een verjaardagskring en een thema-kring.
                        Het lezen en rekenen wordt steeds verder uitgebouwd. Ook het werken met thema’s krijgt meer verdieping, doordat kinderen steeds zelfstandiger worden, ook in het verwerken van lesstof en in het werken binnen een thema, mede door het doen van een studie.
                        Vanaf groep 6 krijgen de kinderen Engels en topografie. Ook hebben de kinderen gym in de gymzaal in de stad, gegeven door een gymleerkracht.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                    <Typography color="black">Groep 7</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <img src="https://source.unsplash.com/random/200x200?sig=45" alt="groep3" />

                    <Typography>
                        In groep 7 krijgen de kinderen meer zelfwerk dat zij inplannen op hun werkplan. Hierbij leren zij steeds meer zelf keuzes te maken en verantwoordelijkheid te dragen voor hun eigen leerproces.

                        Het vergaderen wordt steeds meer van de kinderen zelf, waarbij de leerkracht een minder sturende rol krijgt en meer een coachende taak uitvoert tijdens deze overlegmomenten. Er is een weekendkring, een tekstenkring, een verjaardagskring en een thema-kring.

                        Het lezen en rekenen wordt steeds verder uitgebouwd. Ook het werken met thema’s krijgt meer verdieping, doordat kinderen steeds zelfstandiger worden, ook in het verwerken van lesstof en in het werken binnen een thema. Het doen van studies over een bepaald onderwerp wordt dit leerjaar verder uitgebouwd.

                        Groep 7 (en groep 8) gaat op kamp en de kinderen worden steeds meer aangesproken op hun eigen verantwoordelijkheid, op weg naar de middelbare school.

                        Alle kleuters hebben een maatje, dat is een kind uit groep 7 of 8. Dit maatje begeleidt de kleuter bij de maandviering en tijdens feesten op school.

                        Groep 7 heeft gym in de gymzaal in de stad, gegeven door een gymleerkracht.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                    <Typography color="black">Groep 8</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <img src="https://source.unsplash.com/random/200x200?sig=35" alt="groep3" />

                    <Typography>
                        In groep 8 krijgen de kinderen meer zelfwerk dat zij inplannen op hun werkplan. Hierbij leren zij steeds meer zelf keuzes te maken en verantwoordelijkheid te dragen voor hun eigen leerproces.

                        Het vergaderen wordt steeds meer van de kinderen zelf, waarbij de leerkracht een minder sturende rol krijgt en meer een coachende taak uitvoert tijdens deze overlegmomenten. Er is een weekendkring, een tekstenkring, een verjaardags-kring en een thema-kring.

                        Alle kleuters hebben een maatje, dat is een kind uit groep 7 of 8. Dit maatje begeleidt de kleuter bij de maandviering of tijdens feesten op school.

                        Groep 8 is het afsluitende jaar op de basisschool. Om alvast te ervaren hoe het op de middelbare school zal zijn, hebben we twee VO-weken, waarbij de kinderen per uur een andere les van een andere docent krijgen. Ook gaat groep 8 (met groep 7) op kamp en is er een afscheidsavond die de kinderen zoveel mogelijk zelf vormgeven.
                    </Typography>
                </AccordionDetails>
            </Accordion><br />
        </div>
    );
}