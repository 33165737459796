import React, { useReducer } from "react";
import { generateClient } from "aws-amplify/api";
import { createCreateAgendaItems } from "../graphql/mutations";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const API = generateClient();

function AddCalenderItem() {
    const navigate = useNavigate();
    const [value, setValue] = React.useState(dayjs(new Date()));
    const [agendaItem, setAgendaItem] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            AgendaTitel: "",
            Agendabeschrijving: "",
            Datum: "",
            Afbeelding: "",
            Maand: "",
            Jaar: "",
            Periode: "",
            CreateAt: "",
            UpdatedAt: "",
        }
    );

    const CreateCalendarItem = async () => {
        const newAgendaItems = await API.graphql({
            query: createCreateAgendaItems,
            variables: {
                input: {
                    AgendaTitel: agendaItem.Agendatitel,
                    Agendabeschrijving: agendaItem.Agendabeschrijving,
                    Datum: agendaItem.Datum,
                    Afbeelding: agendaItem.Afbeelding,
                    Maand: agendaItem.Maand,
                    Jaar: agendaItem.Jaar,
                    Periode: agendaItem.Periode,
                    CreateAt: new Date().toISOString(),
                    UpdatedAt: new Date().toISOString(),
                },
            },
        });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;

        setAgendaItem({ [name]: value });
    };

    const handleDateChange = (name, value) => {
        const datename = name;
        const datevalue = dayjs(value.$d).format("YYYY-MM-DD");
        setAgendaItem({ [datename]: datevalue });
        const date = new Date(datevalue);
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        setAgendaItem({ Maand: month });
        setAgendaItem({ Jaar: year });
    };

    const handleSubmit = (event) => {
        CreateCalendarItem();
        navigate("/kalender");

    };

    return (
        <div>
            <Container sx={{ marginTop: "15px", marginBottom: "15px" }} maxWidth="xs">
                <Typography variant="h5" color="initial">
                    Nieuw kalenderitem toevoegen
                </Typography>
                <form>
                    <TextField
                        id="activityName"
                        name="Agendatitel"
                        label="Titel voor activiteit"
                        sx={{ width: "28rem", marginTop: "15px", marginBottom: "15px" }}
                        onChange={handleChange}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            id="activityDate"
                            name="Datum"
                            label="Datum activiteit"
                            value={value}
                            format="YYYY-MM-DD"
                            sx={{ width: "28rem", marginTop: "15px", marginBottom: "15px" }}
                            onChange={(newValue) => handleDateChange("Datum", newValue)}
                        />
                    </LocalizationProvider>
                    <TextField
                        id="activityDescription"
                        name="Agendabeschrijving"
                        label="Beschrijving"
                        multiline
                        rows={5}
                        sx={{ width: "28rem", marginTop: "15px", marginBottom: "15px" }}
                        onChange={handleChange}
                    />
                </form>
                <Button variant="text" onClick={handleSubmit}>
                    Kalenderitem toevoegen
                </Button>
            </Container>
        </div>
    );
}

export default AddCalenderItem;