/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
	aws_project_region: "eu-central-1",
	aws_appsync_graphqlEndpoint:
		"https://uzxdbnl2hnbyxo2rmiuszsvmrm.appsync-api.eu-central-1.amazonaws.com/graphql",
	aws_appsync_region: "eu-central-1",
	aws_appsync_authenticationType: "API_KEY",
	aws_appsync_apiKey: "da2-3l4jk5qci5amzefo3tvteicfrq",
	aws_cognito_identity_pool_id:
		"eu-central-1:601f7997-41c7-486e-a370-d26da9fa04e1",
	aws_cognito_region: "eu-central-1",
	aws_user_pools_id: "eu-central-1_weZ5yFYPw",
	aws_user_pools_web_client_id: "70ul7p752e63bbmko67uoh4hgd",
	oauth: {},
	aws_cognito_username_attributes: ["EMAIL"],
	aws_cognito_social_providers: [],
	aws_cognito_signup_attributes: ["EMAIL", "NAME"],
	aws_cognito_mfa_configuration: "OFF",
	aws_cognito_mfa_types: ["SMS"],
	aws_cognito_password_protection_settings: {
		passwordPolicyMinLength: 8,
		passwordPolicyCharacters: [
			"REQUIRES_LOWERCASE",
			"REQUIRES_NUMBERS",
			"REQUIRES_SYMBOLS",
			"REQUIRES_UPPERCASE",
		],
	},
	aws_cognito_verification_mechanisms: ["EMAIL"],
	aws_user_files_s3_bucket: "windroosassets174556-staging",
	aws_user_files_s3_bucket_region: "eu-central-1",
};

export default awsmobile;
