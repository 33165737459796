import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

function GezondeSchool() {
  return (
    <div style={{ maxWidth: "720px", marginLeft: "10%" }}>
      <Typography variant="h5">De gezonde school...</Typography>
      <img
        src="https://wrassets.boss-tech.nl/beelden/gezondeschool.jpg"
        alt="beeldgezondeschool"
        style={{ width: "100%", borderRadius: "5%" }}
      />
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        De Windroos heeft sinds 2023 het vignet Gezonde School... Met het vignet
        Gezonde School laten wij zien dat onze school voldoet aan de
        kwaliteitscriteria die zijn opgesteld door deskundigen.
        <br />
        <br />
        Zo zorgen we voor actieve en gezonde leerlingen, een veilige
        schoolomgeving, een fris klimaat én hebben we aandacht voor de
        persoonlijke en sociale ontwikkeling van de leerlingen. Dit vinden wij
        belangrijk, omdat het allemaal bijdraagt aan een gezondere leefstijl.
        Onze school heeft bovendien speciale aandacht voor het themacertificaat
        Gezonde Voeding. Gezonde School is een samenwerking tussen diverse
        landelijke organisaties. Wilt u meer weten over (het vignet) Gezonde
        School? Kijk dan op
        <a href="https://www.gezondeschool.nl"> www.gezondeschool.nl</a>
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        In het kader van de gezondy school heeft de Windroos een voedingsbeleid
        opgesteld. Dit beleid is hieronder te downloaden.
        <br></br>
      </Typography>
      <a
        href="https://wrassets.boss-tech.nl/documents/beleid/voedingsbeleidDeWindroos.pdf"
        target="_blank"
        rel="noopener noreferrer"
        download
      >
        <Button variant="text">Download voedingsbeleid</Button>
      </a>
    </div>
  );
}

export default GezondeSchool;
