import React from "react";
import Version from "../components/Version";

function Siteinfo() {
  return (
    <div>
      <Version />
    </div>
  );
}

export default Siteinfo;
