import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { deepOrange } from "@mui/material/colors";
import { Typography } from "@mui/material";
import { generateClient } from "aws-amplify/api";
import { listCreateAgendaItems } from "../graphql/queries";
import { RotateLoader } from "react-spinners";

const API = generateClient();

function Kalender({ props }) {
	const [calendar, setCalendar] = useState({});
	const [loading, setLoading] = useState(true);

	const todayAndFuture = new Date().toISOString();
	const variables = { filter: { Datum: { ge: todayAndFuture } } };

	useEffect(() => {
		const fetchAgendaItems = async () => {
			const agendaItems = await API.graphql({
				query: listCreateAgendaItems,
				variables: variables,
			});
			const data = await agendaItems.data.listCreateAgendaItems;
			await SortCalendar(data);
			setLoading(false);
		};

		fetchAgendaItems();
	}, []);

	function CreateReadableViewDate(date) {
		let readableDate = new Date(date);
		return readableDate.toLocaleDateString("nl-NL");
	}

	function SortCalendar(data) {
		setCalendar({
			items: data.items.sort((a, b) => {
				return new Date(a.Datum) - new Date(b.Datum);
			}),
		});
	}

	return (
		<div>
			<Stack
				direction="column"
				spacing={2}
				sx={{ marginLeft: 2, marginTop: 5, marginBottom: 10 }}
			>
				<Typography variant="h3">Jaarkalender</Typography>

				{loading ? (
					<RotateLoader color={"#EF7D00"} loading={loading} size={200} />
				) : (
					calendar.items.map((event) => (
						<Stack key={event.id} direction="row" spacing={2}>
							<Avatar
								sx={{
									marginRight: 5,
									height: 115,
									width: 115,
									bgcolor: deepOrange[400],
									fontSize: 20,
								}}
							>
								{CreateReadableViewDate(event.Datum)}
							</Avatar>
							<Stack direction="column" spacing={2}>
								<Typography sx={{ marginLeft: 10 }} variant="h5">
									{event.AgendaTitel}
								</Typography>
								<Typography sx={{ marginLeft: 10 }} variant="body1">
									{event.Agendabeschrijving}
								</Typography>
							</Stack>
						</Stack>
					))
				)}
			</Stack>
		</div>
	);
}

export default Kalender;
