// Desc: This is the theme for the Windroos website
import { createTheme } from "@mui/material";

const WindroosTheme = createTheme({
  root: {
    maxWidth: 645,
    background: "rgba(0,0,0,0.5)",
    margin: "20px",
  },

  palette: {
    footer: {
      icon: "#fff",
    },

    rood: {
      main: "#D14D18",
    },
    oranje: {
      main: "#EF7D00",
    },
    blauw: {
      main: "#3C8F99",
    },
    lichtblauw: {
      main: "#88CDD3",
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    h1: {
      fontFamily: "windroos, sans-serif",
      color: "#D14D18",
      padding: "40px",
    },
    h3: {
      fontFamily: "windroos, sans-serif",
      color: "#3C8F99",
      padding: "10px",
      marginBottom: "20px",
    },
    h4: {
      fontFamily: "windroos, sans-serif",
      color: "#3C8F99",
    },

    h5: {
      fontFamily: "windroos, sans-serif",
      color: "#3C8F99",
    },
    h6: {
      fontFamily: "windroos, sans-serif",
    },
  },
  customfont: {
    main: "windroos, sans-serif",
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#88CDD3",
        },
        typography: {
          fontFamily: "windroos, sans-serif",
          color: "#EF7D00",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "windroos, sans-serif",
          fontSize: "1.1rem",
          margin: "0.7rem",
          color: "#EF7D00",
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: "#3C8F99",
          verticalAlign: "top",
          marginTop: "2px",
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: "#fff",
          "&.Mui-selected": {
            color: "#EF7D00",
          },
          verticalAlign: "top",
          marginTop: "2px",
        },
        label: {
          fontFamily: "Windroos, sans-serif",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "#EF7D00",
          fontFamily: "windroos, sans-serif",
        },
      },
    },
  },
});

export default WindroosTheme;
